<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Customers</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Customers"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              depressed
              light
              :to="{ name: 'module-paw-customers-archived' }"
            >
              <v-icon left>mdi-archive</v-icon>View Archived
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="customers"
            no-data-text="No customers found"
          >
            <template v-slot:item.full_name="{ item }">
              <router-link
                :to="{
                  name: 'module-paw-customers-individual',
                  params: { customerId: item.id },
                }"
                >{{ item.full_name }}</router-link
              >
            </template>

            <template v-slot:item.last_delivery="{ item }">
              <div v-if="item.lastDelivery">
                {{ item.lastDelivery.estimated_delivery }}
              </div>
            </template>
            <template v-slot:item.subscription="{ item }">
              <div v-for="subsc in item.subscriptions" v-bind:key="subsc.id">
                <router-link
                  :to="{
                    name: 'module-paw-subscriptions-individual',
                    params: { subscriptionId: subsc.id },
                  }"
                >
                  {{ subsc.identifier }}<br
                /></router-link>
              </div>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    :to="{
                      name: 'module-paw-customers-individual',
                      params: { customerId: item.id },
                    }"
                  >
                    <v-icon x-small>mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>View Customer</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      tableHeaders: [
        { text: "Name", value: "full_name" },
        { text: "Email", value: "email" },
        { text: "Last Delivery", value: "last_delivery" },
        { text: "Subscription", value: "subscription" },
        { text: "Actions", value: "actions", align: "end" },
      ],
    };
  },

  computed: {
    customers() {
      let customers = this.$store.state.paw["customers"];

      if (this.searchTerm !== "") {
        customers = customers.filter((c) => {
          const name = c.full_name.toLowerCase();
          const email = c.email.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return name.includes(searchTerm) || email.includes(searchTerm);
        });
      }

      return customers;
    },
  },
};
</script>
